<template>
	<div>
		<!-- Mobile Elipsis Version of the Menu -->
		<div class="d-flex d-md-none">
			<v-menu transition="slide-x-transition" bottom left>
				<template v-slot:activator="{ on }">
					<v-icon v-on="on">mdi-dots-vertical</v-icon>
				</template>

				<v-list v-for="(status, key) in items" :key="key" class="py-0">
					<v-list-item-group
						v-if="key === 'All' || key === siteStatus"
						style="minWidth: 230px"
					>
						<v-list-item
							v-for="(item, index) in status"
							:key="index"
							:to="{
								name: item.routeName,
								params: { id: $route.params.id }
							}"
						>
							<v-list-item-avatar large>
								<v-img :src="item.icon"></v-img>
							</v-list-item-avatar>
							<v-list-item-title>{{
								item.title
							}}</v-list-item-title>
						</v-list-item>
						<v-divider></v-divider>
					</v-list-item-group>
				</v-list>
			</v-menu>
		</div>

		<!-- Desktop Action Menu -->
		<v-card class="d-none d-md-flex pa-2" flat outlined>
			<span v-for="(status, key) in items" :key="key">
				<span v-if="key !== 'All' && key === siteStatus">
					<router-link
						v-for="(item, index) in status"
						:key="index"
						class="mx-2"
						:to="{
							name: item.routeName,
							params: { id: $route.params.id }
						}"
					>
						<!--<img :src="item.icon" height="30vmin" />-->
						<strong>{{ item.title }}</strong>
						<v-divider
							class="ml-4"
							v-show="index !== status.length - 1"
							vertical
						></v-divider>
					</router-link>
				</span>
			</span>
		</v-card>
	</div>
</template>

<script>
export default {
	name: "sellerActions",
	props: ["siteStatus"],
	data() {
		return {
			items: {
				All: [
					{
						title: "Edit Details",
						icon: require("@/assets/icons/seller_amend posting detail.svg"),
						routeName: "AmendPostDetail"
					},
					{
						title: "Edit Assets",
						icon: require("@/assets/icons/seller_amend posting assets.svg"),
						routeName: "AmendPostAssets"
					},
					{
						title: "Edit Description",
						icon: require("@/assets/icons/seller_amend posting description.svg"),
						routeName: "AmendPostDescription"
					},
					{
						title: "Edit Primary Category",
						icon: require("@/assets/icons/seller_amend posting category.svg"),
						routeName: "AmendPostPrimaryCategory"
					},
					{
						title: "Edit Additional Categories",
						icon: require("@/assets/icons/seller_amend posting additional categories.svg"),
						routeName: "AmendPostCategories"
					}
				],
				"For Sale": [
					{
						title: "Modify End Date",
						icon: require("@/assets/icons/seller_extend posting.svg"),
						routeName: "ExtendPost"
					},
					{
						title: "Withdraw Post",
						icon: require("@/assets/icons/seller_withdraw posting.svg"),
						routeName: "WithdrawPost"
					},
					{
						title: "Email Post",
						icon: require("@/assets/icons/seller_email posting.svg"),
						routeName: "EmailPost"
					}
				],
				Scheduled: [
					/*
					{
						title: "Modify Post Dates",
						icon: require("@/assets/icons/seller_change posting date.svg"),
						routeName: "ModifyPostDates"
					},
					*/
					{
						title: "Modify End Date",
						icon: require("@/assets/icons/seller_extend posting.svg"),
						routeName: "ExtendPost"
					},
					{
						title: "Withdraw Post",
						icon: require("@/assets/icons/seller_withdraw posting.svg"),
						routeName: "WithdrawPost"
					},
					{
						title: "Email Post",
						icon: require("@/assets/icons/seller_email posting.svg"),
						routeName: "EmailPost"
					}
				],
				Draft: [
					{
						title: "Post Draft",
						icon: require("@/assets/icons/seller_post draft.svg"),
						routeName: "PostDraft"
					},
					{
						title: "Email Post",
						icon: require("@/assets/icons/seller_email posting.svg"),
						routeName: "EmailPost"
					}
				],
				Withdrawn: [
					{
						title: "Repost",
						icon: require("@/assets/icons/seller_add post.svg"),
						routeName: "Repost"
					},
					{
						title: "Email Post",
						icon: require("@/assets/icons/seller_email posting.svg"),
						routeName: "EmailPost"
					}
				],
				Expired: [
					{
						title: "Repost",
						icon: require("@/assets/icons/seller_add post.svg"),
						routeName: "Repost"
					},
					{
						title: "Email Post",
						icon: require("@/assets/icons/seller_email posting.svg"),
						routeName: "EmailPost"
					}
				]
			}
		};
	}
};
</script>